import React from 'react';
import { EmptyLayout, Paragraph as P, Link as A } from '@periodica/ui-kit';
import { Link } from 'gatsby';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../../components/Layouts/Layout';
import { OneColumn } from '../../components/Layouts/OneColumn';
import SEO from '../../components/Layouts/SEO';

export function Head() {
  return (
    <>
      <SEO title="Ошибка оплаты" description="Во время оплаты заказа произошла ошибка" />
      <YandexTableau />
    </>
  );
}

function PaymentErrorPage() {
  return (
    <Layout>
      <OneColumn>
        <EmptyLayout
          hasIcon={false}
          title="Ой, ошибка оплаты!"
          description={
            <P size="inherit">
              Мы получили ваш&nbsp;заказ и&nbsp;отправили подтверждение на&nbsp;почту. Попробуйте
              оплатить по&nbsp;ссылке в&nbsp;письме или&nbsp;
              <A asChild>
                <Link to="/contacts">свяжитесь с&nbsp;нами</Link>
              </A>{' '}
              любым удобным способом.
            </P>
          }
        />
      </OneColumn>
    </Layout>
  );
}

export default PaymentErrorPage;
